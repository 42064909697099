const units = [
  'bytes',
  'KB',
  'MB',
  'GB',
  'TB',
  'PB'
];

export const convertBytes = function(bytes = 0, precision = 0, decimal = false ) {
  if ( isNaN( parseFloat( String(bytes) )) || ! isFinite( bytes ) ) return '?';

  let unit = 0;
  let breakpoint;
  breakpoint = decimal ? 1000 : 1024;

  while ( bytes >= breakpoint ) {
    bytes /= breakpoint;
    unit ++;
  }

  return bytes.toFixed( + precision ) + ' ' + units[ unit ];
}