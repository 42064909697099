<script>
import libraryService from '@/services/library.service'
import Photo from '@/data/models/Photo'
import EditLayout from '@/layout/EditLayout'
import { convertBytes } from '@/utils/convert-bytes'

export default {
  name: 'SingleLibrary',
  components: {
    EditLayout
  },
  data() {
    return {
      items: [],
      item: new Photo,
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
  },  
  mounted() {
    this._get()
  },
  methods: {
    convertBytes,
    _get() {
      libraryService.getById({ id: this.id })
        .then(res => {
          this.item = res.data
        })
    },
    edit() {
      const preparedItem = this._prepareItem({...this.item})
      libraryService.edit({ data: preparedItem })
        .then(_res => {
          this.$notify({
            message: this.$t('m.itemSuccessfullySaved', { item: this.$tc('file') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
        })
    },
    _prepareItem(item) {
      delete item.height
      delete item.media
      delete item.photo
      delete item.size
      delete item.type
      delete item.width
      return item
    }
  }
}
</script>

<template>
  <EditLayout 
    :item="item"
    :submit-button-text="$t('save')"
    :supports-editor="false"
    @submit="edit()"
  >
    <div slot="title">
      <label>{{ $t('filenamePreview') }}</label>
      <h4 class="titlePreview">
        {{ item.filename }}.{{ item.type }}
      </h4>
    </div>
    <div slot="middle">
      <div
        v-if="item && item.type !== 'pdf' && item.photo"
        class="photoContainer"
      >
        <BaseImage
          :source="item"
          size="medium"
        />
      </div>
      <div
        v-if="item.type === 'pdf'"
        class="text-center"
      >
        <BaseIcon
          icons-group="far"
          icon="fa-file-pdf"
          size="xxl"
        />
        <br><br>
      </div>
    </div>
    <div slot="sidebar-middle">
      <BaseInput
        v-model="item.title"
        :label="$t('altTitle')"
        :placeholder="$t('enterAltTitle')"
      />
      <BaseInput
        v-model="item.caption"
        :label="$t('caption')"
        :placeholder="$t('enterPhotoCaption')"
      />
      <BaseInput
        v-model="item.filename"
        :label="$t('renameFileExcludeType')"
        :placeholder="$t('enterNewNameWithoutType')"
      />
      <BaseInput
        :value="`${item.type}`"
        :label="$tc('type')"
        disabled
      />
      <BaseInput
        :value="`${item.width} px`"
        :label="$t('width')"
        disabled
      />
      <BaseInput
        :value="`${item.height} px`"
        :label="$t('height')"
        disabled
      />
      <BaseInput
        :value="`${item.size >= 1000 ? convertBytes(item.size) : item.size + ' Bytes'}`"
        :label="$t('fileSize')"
        disabled
      />
    </div>
  </EditLayout>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.photoContainer
  position: relative
.titlePreview
  color: $c-boulder-grey
</style>