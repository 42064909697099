/* eslint-disable camelcase */
export default class Photo {   
  caption;
  filename;
  height;
  id;
  media;
  size;
  title;
  type;
  width;

  constructor() {
    this.caption = "";
    this.filename = "";
    this.height = "";
    this.id = null;
    this.media = "";
    this.size = "";
    this.title = "";
    this.type = "";
    this.width = "";
  }
}